import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, Subject } from 'rxjs';
import { delay, filter, retryWhen, switchMap, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { environment } from './../../../environments/environment';
import { BaseState } from './../state/base/base.state';
import { HelperService } from './helper.service';
import { StorageService } from './storage.service';

export const RECONNECT_INTERVAL = environment.webSocketHelloDoc.reconnectInterval;
@Injectable({
    providedIn: 'root',
})
export class HdWebsocketService {
    //https://medium.com/briebug-blog/making-use-of-websockets-in-angular-way-easier-than-you-expected-25dd0061db1d
    // private socket$;
    // private messagesSubject$ = new Subject();
    // public messages$ = this.messagesSubject$.pipe(
    //     switchAll(),
    //     catchError((e) => {
    //         throw e;
    //     }),
    // );

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private connection$: WebSocketSubject<any>;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private initData: any;

    public onReconnect: Subject<void> = new Subject();

    constructor(
        private helper: HelperService,
        private store: Store,
        private storage: StorageService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    connect(): Observable<any> {
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const token: any = this.storage.getToken();
        // this.connection$ = webSocket(
        //     this.helper.getWSHelloDocUrl() + `/contractors/${contractorId}/customers?auth_token=${token?.access_token}`,
        // );

        const c = this.helper.getWSHelloDocUrl() + `/contractors/${contractorId}/customers?auth_token=${token?.access_token}`;

        return of(c).pipe(
            filter(apiUrl => !!apiUrl),
            // https becomes wws, http becomes ws
            switchMap(wsUrl => {
                if (this.connection$) {
                    return this.connection$;
                } else {
                    this.connection$ = webSocket(wsUrl);
                    return this.connection$;
                }
            }),
            retryWhen(errors =>
                errors.pipe(
                    delay(RECONNECT_INTERVAL),
                    tap(() => {
                        console.log('RECONNECT HD WS');
                        if (this.initData) {
                            this.send(this.initData);
                            this.onReconnect.next();
                            // this.send(this.initData);
                        }
                    }),
                ),
            ),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public initCall(data: any): void {
        this.initData = data;
        this.send(data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    send(data: any) {
        if (this.connection$) {
            this.connection$.next(data);
        } else {
            console.error('Did not send data, open a connection first');
        }
    }
}
