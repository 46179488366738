import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import momentPlugin from '@fullcalendar/moment';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import { AppBasicsModule, AppBasicsModuleConfigModel } from '@meddev/fe-shared';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
// import { HotkeyModule, HotkeysService } from 'angular2-hotkeys';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { IntercomModule } from 'ng-intercom';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { PublicComponent } from './public/public.component';
import { PublicState } from './public/state/public.state';
import { ThemeComponent } from './theme/frames/default/theme.component';
import { NoFooterComponent } from './theme/frames/no-footer-fixed/no-footer-fixed.component';
import { LayoutModule } from './theme/layouts/layout.module';
import { TeethState } from './theme/pages/home/dentist/_state/teeth.state';
import { DocumentationOverviewModule } from './theme/pages/home/documentation-overview/documentation-overview.module';
import { TelephonyModule } from './theme/pages/home/telephony/telephony.module';
import { AvailabilityState } from './theme/pages/settings/availability-calendar/_state/availability.state';
import { TelemedicineModule } from './theme/pages/telemedicine/telemedicine.module';
import { ThemeRoutingModule } from './theme/theme-routing.module';
import { TokenInterceptor } from './token.interceptor';
import { ScriptLoaderService } from './_services/script-loader.service';
import { EnvironmentHelper } from './_shared/helper/environment-helper';
import { RulesService } from './_shared/services';
import { MenuService } from './_shared/services/menu.service';
import { OneSignalService } from './_shared/services/onesignal.service';
import { BaseState } from './_shared/state/base/base.state';
import { ExaminationState } from './_shared/state/examinations/examinations-state';
import { LaboratoryState } from './_shared/state/laboratory/laboratory-state';
import { TreatmentState } from './_shared/state/treatment/treatment.state';
import { WebsocketState } from './_shared/state/websocket/websocket.state';

EnvironmentHelper.initEnvironment();

const provideSwal = Swal.mixin({
    buttonsStyling: false,
    confirmButtonColor: null,
    cancelButtonColor: null,
    customClass: {
        cancelButton: 'btn btn-secondary m-btn m-btn--custom',
        confirmButton: 'btn btn-success m-btn m-btn--custom',
    },
});

FullCalendarModule.registerPlugins([
    // register FullCalendar plugins
    dayGridPlugin,
    timeGridPlugin,
    momentPlugin,
    interactionPlugin,
    resourceTimeGridPlugin,
    listPlugin,
    resourceTimelinePlugin,
]);

@NgModule({
    declarations: [ThemeComponent, AppComponent, PublicComponent, NoFooterComponent],
    imports: [
        NgbModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        AuthModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
        }),
        SweetAlert2Module.forRoot({
            provideSwal,
        }),
        NgxPermissionsModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        IntercomModule.forRoot({
            appId: environment.INTERCOM_KEY,
            updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
        }),
        HttpClientXsrfModule,
        HttpClientModule,
        NgxsModule.forRoot(
            [BaseState, TreatmentState, WebsocketState, PublicState, TeethState, AvailabilityState, ExaminationState, LaboratoryState],
            {
                developmentMode: !environment.production,
            },
        ),
        NgxsStoragePluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
        TelemedicineModule,
        FullCalendarModule,
        TelephonyModule,
        DocumentationOverviewModule,
        AppBasicsModule.forRoot({
            version: environment.VERSION,
            production: environment.production,
            releaseStage: environment.RELEASE_STAGE as AppBasicsModuleConfigModel['releaseStage'],
            matomo: {
                siteId: '16',
                trackerUrl: 'https://data.medifit.si/',
            },
            bugSnag: {
                apiKey: '4c8b0c632c20c7d5d987858bb90936a3',
            },
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        ScriptLoaderService,
        RulesService,
        { provide: LOCALE_ID, useValue: 'sl' },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        // HotkeysService,
        OneSignalService,
        MenuService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
