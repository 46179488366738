import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Directive, EventEmitter, Injectable, Output } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PaperHistoryElement, PortfolioPaper } from '../../../../../../_shared/model';
import { HelperService } from '../../../../../../_shared/services';
import { PaperType } from './../../../../../../_shared/enums/PaperType.enum';
import { Case } from './../../../../../../_shared/model/schedule/case.model';
import { OpenDocumentRequest } from './../../../../../../_shared/model/utils/openDocumentRequest.model';
import { StorageService } from './../../../../../../_shared/services/storage.service';
import { CaseService } from './../../../_services/case.service';
import { DocumentsService } from './../../../_services/documents.service';
import { ResultService } from './..//../../_services/result.service';

@Directive()
@Injectable({
    providedIn: 'root',
})
export class PfPCommnComunicationService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onCopy: EventEmitter<PaperHistoryElement> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSave: EventEmitter<boolean> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onDelete: EventEmitter<boolean> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onPreview: EventEmitter<boolean> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onPrint: EventEmitter<boolean> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onPaperChanged: EventEmitter<PortfolioPaper> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() onSignDocument: EventEmitter<any> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSignDocumentExecuted: EventEmitter<boolean> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() onTemplateSaveFTH: Subject<any> = new Subject();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() onTemplateSaveSurvey: Subject<any> = new Subject();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() onTemplateSelect: EventEmitter<any> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Output() resetValues: EventEmitter<any> = new EventEmitter();

    private onDestroy$ = new Subject<void>();
    public dirty$ = new BehaviorSubject(false);

    constructor(
        private doc: DocumentsService,
        private caseRest: CaseService,
        private storage: StorageService,
        private rest: ResultService,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public appendDocumentToCase(casee: Case, document: Blob, documentName: string = 'dokument.pdf'): Observable<any> {
        const file: File = new File([document], documentName, { type: 'application/pdf' });
        return this.doc.uploadFile(file, documentName).pipe(
            filter(res => res.type == HttpEventType.Response),
            switchMap(documentId => {
                return this.caseRest.addCaseContractorDocument(this.storage.getSelectedContractor().id, casee.bsid, casee.caseId, documentId.body);
            }),
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public appendSavedDocumentToCase(casee: Case, documentId: string): Observable<any> {
        return this.caseRest.addCaseContractorDocument(this.storage.getSelectedContractor().id, casee.bsid, casee.caseId, documentId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openSignedPreview(paper: PortfolioPaper): Observable<any> {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let previewDoc: Observable<any> = this.rest.getSurveyPaperPreview(paper?.visitId, paper.id, true);

        if (paper.paperType === PaperType.WHITE_REFERRAL) {
            previewDoc = this.rest.printPortfolioPaper(this.storage.getSelectedContractor().id, paper.id, true);
        }

        return previewDoc.pipe(
            takeUntil(this.onDestroy$),
            filter(res => res.type == HttpEventType.Response),
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((res: HttpResponse<any>) => {
                const document: Blob = new Blob([res.body], {
                    type: 'application/pdf',
                });

                return { document: document };
            }),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openSignedPreviewAppend(paper: PortfolioPaper, caseData: Case, name: string): Observable<any> {
        return this.openSignedPreview(paper)
            .pipe(
                switchMap((res: OpenDocumentRequest) => {
                    const filename = `${_.kebabCase(name)}-${moment().format('DD.MM.YYYY-HH:mm')}.pdf`;
                    return this.helper.openDocumentViewerSimple(res, filename, false).pipe(
                        filter(() => !_.isNil(caseData)),
                        switchMap(() => {
                            return this.appendDocumentToCase(caseData, res.document, filename);
                        }),
                    );
                }),
            )
            .pipe(
                tap(() => {
                    this.onSignDocumentExecuted.next(true);
                }),
            );
    }

    public signAndShow(paper: PortfolioPaper, caseData?: Case) {
        //če dodaš caseData argument, pomeni da boš appendal dokument avtorizaciji
        this.rest
            .getSignedPaper(this.storage.getSelectedContractor().id, paper.visitId, paper.paperId || paper.id)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        const document: Blob = new Blob([res.body], {
                            type: 'application/pdf',
                        });
                        return new OpenDocumentRequest().deserialize({ document: document });
                    }
                }),
            )
            .subscribe(res => {
                paper.signedPaper = true;
                if (res) {
                    if (caseData) {
                        //dodaj avtorizaciji
                        this.rest
                            .getLastSigned(this.storage.getSelectedContractor().id, paper.id)
                            .pipe(
                                switchMap(documentId => {
                                    if (documentId) {
                                        return this.appendSavedDocumentToCase(caseData, documentId);
                                    }
                                }),
                            )
                            .subscribe(() => {
                                this.onSignDocumentExecuted.emit();
                            });
                        this.helper.openDocumentViewerSimple(res, 'Listina');
                    } else {
                        this.helper.openDocumentViewerSimple(res, 'Listina');
                        this.onSignDocumentExecuted.emit();
                    }
                }
            });
    }

    public saveSignShareAndShow(
        paper: PortfolioPaper,
        data: { case: Case; canShareData: boolean },
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        save$: () => Observable<any>,
        caseUpdate: (casee: Case) => void,
        isMfmHdSurvey,
        isHelloDoctorModuleEnabled,
        isPreventivniBs,
    ): void {
        of(null)
            .pipe(
                switchMap(() => {
                    const caseId = _.get(data.case, 'caseId');
                    const shouldUpdateCase = caseId && (!isMfmHdSurvey || (isMfmHdSurvey && !isHelloDoctorModuleEnabled));

                    if (shouldUpdateCase) {
                        caseUpdate(data.case);
                        if (!isPreventivniBs) {
                            if (data?.canShareData || data.case?.customerOptIn) {
                                return of({ value: true });
                            } else {
                                const surveyProperties = isMfmHdSurvey
                                    ? {
                                          confirmButtonText: 'Pošlji zavarovalnici',
                                          cancelButtonText: 'Samo podpiši',
                                          confirmButtonClass: 'btn btn-danger m-btn m-btn--custom m-btn--pill',
                                          cancelButtonClass: 'btn btn-warning m-btn m-btn--custom m-btn--pill',
                                          allowOutsideClick: false,
                                          allowEscapeKey: false,
                                      }
                                    : {
                                          confirmButtonText: 'Pošilji zavarovalnici',
                                          cancelButtonText: 'Samo podpiši',
                                          allowOutsideClick: false,
                                          allowEscapeKey: false,
                                      };

                                return from(
                                    this.helper.displayAlert(
                                        'Pošiljanje listine zavarovalnici',
                                        'Stranka ni podala dovoljenja za posredovanje listine. Ali želite, da se listina kljub temu posreduje zavarovalnici?',
                                        surveyProperties,
                                    ),
                                );
                            }
                        } else if (
                            isPreventivniBs &&
                            [
                                PaperType.WORK_CERTIFICATE,
                                PaperType.WEAPON_CERTIFICATE,
                                PaperType.DRIVER_CERTIFICATE,
                                PaperType.DRIVER_CERTIFICATE_SPECIAL,
                            ].includes(paper.paperType)
                        ) {
                            return of({ value: true });
                        }
                    }
                    return of({ value: false });
                }),
                switchMap(res => {
                    return save$().pipe(tap(() => this.signAndShow(paper, res?.value ? data.case : null)));
                }),
            )
            .subscribe();
    }
}
