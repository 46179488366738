import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DataPipe } from './pipes/data.pipe';
import { ExaminationStatusPipe } from './pipes/examination-status.pipe';
import { LabScientificNotationPipe } from './pipes/lab-scientific-notation.pipe';
import { LinkTypePipe } from './pipes/link-type.pipe';
import { NumberToMonthPipe } from './pipes/number-to-month.pipe';
import { SettingsTypePipe } from './pipes/settings-type.pipe';
import { SurveysTypePipe } from './pipes/surveys-type.pipe';
import { TermStatusPipe } from './pipes/term-status.pipe';
import { TriageStatusPipe } from './pipes/triage-status.pipe';
import { HelperService, StorageService } from './services';
import { HelperNotificationService } from './services/helper-notification.service';
import { HttpClientService } from './services/http-client.service';
import { NgbDateMomentParserFormatter } from './services/NgbDateMomentParserFormatter.class';
import { DateValidator } from './validators/date.validator';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule, NgxPermissionsModule, HttpClientXsrfModule],
    declarations: [
        CapitalizePipe,
        DataPipe,
        TriageStatusPipe,
        SettingsTypePipe,
        SurveysTypePipe,
        TermStatusPipe,
        LinkTypePipe,
        NumberToMonthPipe,
        ExaminationStatusPipe,
        LabScientificNotationPipe,
    ],
    providers: [
        StorageService,
        HelperService,
        HttpClientService,
        NgbDateMomentParserFormatter,
        DateValidator,
        HelperNotificationService,
        CapitalizePipe,
    ],
    exports: [
        NgxPermissionsModule,
        DataPipe,
        TriageStatusPipe,
        SettingsTypePipe,
        SurveysTypePipe,
        TermStatusPipe,
        LinkTypePipe,
        NumberToMonthPipe,
        ExaminationStatusPipe,
        LabScientificNotationPipe,
    ],
})
export class SharedModule {}
