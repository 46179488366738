import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TphACUser } from '../model/tph-AC-user.model';
import { User } from './../../../../../_shared/model/user.model';
import { MfToastService } from './../../../../../_shared/services/mfToast.service';
import { BaseState } from './../../../../../_shared/state/base/base.state';
import { TelephonyService } from './../telephony.service';

@Component({
    selector: 'tph-new-user',
    templateUrl: './tph-new-user.component.html',
    styleUrls: ['./tph-new-user.component.scss'],
})
export class TphNewUserComponent implements OnInit {
    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    onRegister: EventEmitter<any> = new EventEmitter();

    public isConsent = 0;
    public registerForm: FormGroup;
    public isSubmited = false;
    constructor(
        private fb: FormBuilder,
        private store: Store,
        private rest: TelephonyService,
        private toast: MfToastService,
    ) {}

    ngOnInit() {
        this.createForm();
    }

    public consent(isConsent: boolean): void {
        this.isConsent = isConsent ? 2 : 1;
    }

    private createForm(): void {
        const user: User = this.store.selectSnapshot(BaseState.LoggedUser);
        this.registerForm = this.fb.group({
            firstName: new FormControl(user.name.split(' ')[0], Validators.required),
            lastName: new FormControl(user.name.split(' ')[1], Validators.required),
            email: new FormControl(user.email, [Validators.required, Validators.email]),
        });
    }

    public onSubmit(): void {
        this.isSubmited = true;
        if (!this.registerForm.valid) {
            return;
        }
        const cid: number = this.store.selectSnapshot(BaseState.activeContractorId);

        this.rest.registerNewUser(cid, this.registerForm.value).subscribe(
            ({ user }: { user: TphACUser }) => {
                user.id;
                this.isConsent = 3;
            },
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            err => {
                this.toast.error('Napaka pri registraciji');
            },
        );

        //api call
    }

    get formData() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <any>this.registerForm.controls;
    }

    public login(): void {
        this.onRegister.emit();
    }
}
